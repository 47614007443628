<template>
  <section>
    <!-- <HeaderSection :title="$t('common.stores')" /> -->
    <div
      class="relative flex lg:h-full w-full flex-col-reverse lg:flex-row pt-16 lg:pt-[84px] h-screen lg:w-full"
    >
      <Closemobile
        v-if="state.isMobileDrawer"
        @click="state.isMobileDrawer = !state.isMobileDrawer"
        :class="state.isMobileDrawer ? 'z-[10002]' : ''"
        class="absolute w-10 h-10 right-2 closemobile"
      />
      <div
        v-if="state.isMobileDrawer"
        class="absolute inset-0 bg-black bg-opacity-50"
        :class="state.isMobileDrawer ? 'z-[10000]' : ''"
        @click="state.isMobileDrawer = !state.isMobileDrawer"
      />
      <div
        :class="state.isMobileDrawer ? 'active z-[10002]' : 'z-10'"
        class="relative transition-[height] -mt-2 lg:mt-0 duration-200 bg-white h-full bottom-0 display-sidebar overflow-hidden md:z-10 left-0 md:border md:border-white lg:pb-11 rounded-t-2xl lg:rounded-none md:w-full right-0 lg:px-10 py-8 lg:w-[364px] xl:w-[446px]"
      >
        <!-- Drawer toggle icon -->
        <div
          class="absolute inset-x-0 flex justify-center mb-4 cursor-pointer md:hidden top-4"
          @click="onToggleDrawer"
        >
          <!-- <img src="@/assets/svg/drawer-icon.svg" alt=""> -->
          <IconsDrawer />
        </div>
        <div
          class="grid grid-cols-1 px-4 space-y-6 lg:mt-0 lg:inline-block lg:w-full md:space-y-0 md:gap-6 md:grid-cols-2 lg:gap-0 lg:grid-cols md:px-8 lg:px-0"
        >
          <!-- Search input -->
          <div class="relative block">
            <span
              class="absolute inset-y-0 flex items-center ltr:left-0 rtl:right-0 ltr:pl-4 rtl:pr-4"
            >
              <Search2LineIcon class="w-5 h-5" />
            </span>

            <input
              id="autocompleteStart"
              ref="autocompleteInput"
              class="block w-full ltr:pl-10 rtl:pl-14 ltr:pr-14 rtl:pr-10 py-[14px] bg-white border rounded-xl shadow-sm placeholder:italic placeholder:text-slate-400 border-slate-300 focus:outline-none focus:border-primary focus:ring-primary focus:ring-1 sm:text-sm"
              placeholder="Search"
              :class="isEmpty ? 'border-primary-600' : 'border-[#D9D9D9]'"
              type="text"
              name="search"
              @focus="onOpenDrawer"
              @input="onSearch"
            />

            <div
              class="absolute text-gray-500 bg-white border-0 top-3.5 bottom-3.5 end-12"
            >
              <span class="border-l border-[#D9D9D9] h-full inline" />
            </div>
            <client-only>
              <span
                v-click-away="away"
                @keydown.escape="isDropdownOpen = false"
              >
                <button
                  class="absolute transition-all ease-in-out transform -translate-y-1/2 bg-white border-0 end-4 top-1/2 hover:animate-pulse"
                  @click.prevent="onSwitchDropdown"
                >
                  <EqualizerLineIcon class="w-[18px] h-[18px]" />
                </button>
                <div v-show="isDropdownOpen" class="relative">
                  <div class="absolute z-50 ltr:right-0 rtl:left-0 top-2">
                    <div
                      class="bg-[#FFFFFF] border border-gray-200 rounded-[10px] px-5 py-[14px]"
                      style="
                        box-shadow: 0px 10px 30px rgba(147, 148, 151, 0.36);
                      "
                    >
                      <ul class="flex flex-row pl-0 mb-4 list-none border-b-0">
                        <!-- GMail link -->
                        <li>
                          <span
                            class="block px-3 py-2 text-sm font-medium uppercase border-b no-underline border-primary"
                            :class="[
                              activetab === 'km'
                                ? 'text-primary rounded border-b-4 border-primary cursor-default'
                                : 'cursor-pointer no-underline border-b border-[#EAEAEA] ',
                            ]"
                            @click="activeTabMethod('km')"
                          >
                            KM
                          </span>
                        </li>
                        <!-- Apple Mail link -->
                        <li>
                          <span
                            class="block px-3 py-2 text-sm font-medium uppercase border-b no-underline border-primary"
                            :class="[
                              activetab === 'ml'
                                ? 'text-primary rounded border-b-4 border-primary cursor-default'
                                : 'cursor-pointer no-underline border-b border-[#EAEAEA] ',
                            ]"
                            @click="activeTabMethod('ml')"
                          >
                            ML
                          </span>
                        </li>
                      </ul>

                      <ul class="mt-5 ml-2">
                        <li>
                          <div class="flex items-center mr-4">
                            <input
                              id="50"
                              v-model="distance"
                              type="radio"
                              value="50"
                              class="w-4 h-4 bg-white border-black text-primary focus:ring-2"
                              @change="
                                searchPlaceWithFill({
                                  use_distance: searchQuery.length > 0,
                                })
                              "
                            />
                            <label
                              for="50"
                              class="font-normal text-black ltr:ml-2 rtl:mr-2 text-md dark:text-black"
                              >50</label
                            >
                          </div>
                        </li>
                        <li class="my-3">
                          <div class="flex items-center mr-4">
                            <input
                              id="25"
                              v-model="distance"
                              type="radio"
                              value="25"
                              class="w-4 h-4 bg-white border-black text-primary focus:ring-2"
                              @change="
                                searchPlaceWithFill({
                                  use_distance: searchQuery.length > 0,
                                })
                              "
                            />
                            <label
                              for="25"
                              class="font-normal text-black ltr:ml-2 rtl:mr-2 text-md dark:text-black"
                              >25</label
                            >
                          </div>
                        </li>
                        <li>
                          <div class="flex items-center mr-4">
                            <input
                              id="15"
                              v-model="distance"
                              type="radio"
                              value="15"
                              class="w-4 h-4 bg-white border-black text-primary focus:ring-2"
                              @change="
                                searchPlaceWithFill({
                                  use_distance: searchQuery.length > 0,
                                })
                              "
                            />
                            <label
                              for="15"
                              class="font-normal text-black ltr:ml-2 rtl:mr-2 text-md dark:text-black"
                              >15</label
                            >
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </span>
            </client-only>
          </div>

          <!-- Tabs -->
          <div class="md:py-0 lg:py-6">
            <div class="relative">
              <BaseTab :tabs="tabs" @activeTab="storeInfoCurrentTab" />
            </div>
          </div>
        </div>

        <!-- Store Cards Info -->
        <div
          class="items-stretch justify-start w-full h-full px-4 pt-5 overflow-x-scroll md:px-8 overscroll-none lg:pt-0 lg:px-0 lg:items-center lg:inline-block md:flex lg:space-x-0 no-scrollbar card-scrollebar md:overflow-x-scroll lg:overflow-y-scroll md:space-x-4"
        >
          <div
            v-for="(store, index) in stores"
            :key="index"
            class="my-3 min-w-[266px] md:min-w-[360px] lg:min-w-full"
            @click="goToMarker(index)"
          >
            <TabStoreInfoCards
              baidu
              :distance="store.distance"
              :info="store.info"
              :show-detail="selectedStore === index"
              :position="store.position"
            />
          </div>
          <div v-if="isLoading" class="flex flex-col md:flex-row lg:flex-col">
            <div v-for="index in new Array(4)" :key="index">
              <ContentLoader
                class="h-[300px] w-full md:w-[360px] py-4 md:px-4 lg:px-0 rounded-xl overflow-hidden"
                height="300"
                :speed="2"
                view-box="0 0 400 300"
              >
                <rect x="0" y="0" rx="5" ry="5" width="100%" height="300" />
              </ContentLoader>
            </div>
          </div>
          <div
            v-else-if="!stores.length && !isLoading"
            class="flex flex-col items-center justify-center w-full"
          >
            <IconsEmpty />
            <p class="mt-4 empty-state-title">
              {{ $t("common.no_available_stores_at_the_momemt") }}
            </p>
          </div>
        </div>
      </div>

      <!-- Map Tabs -->
      <div
        class="relative z-0 flex-1 h-full overflow-hidden md:w-full md:h-auto"
      >
        <div
          class="absolute inset-x-0 z-10 flex items-center w-auto px-5 overflow-x-scroll no-scrollbar top-8"
        >
          <button
            v-for="(s, si) in servicesList"
            :key="si"
            class="inline-block mb-2 cursor-pointer me-2"
            @click.prevent="toggleService(s)"
          >
            <span
              :class="[
                services.indexOf(s) !== -1
                  ? 'bg-[#2C3032] text-white'
                  : 'bg-white text-black',
                'flex rounded-[50px] items-center justify-center w-auto py-2 px-4 md:px-[22px] text-sm font-medium capitalize',
              ]"
            >
              <span
                class="w-auto whitespace-nowrap"
                v-text="$t(`common.${s}`)"
              />
            </span>
          </button>
        </div>
        <div id="map" ref="mapUi" class="w-full h-full pb-3 xl:w-full" />
        <div class="absolute right-[130px] !bottom-[10px] z-[1000]">
          <div id="normalMapBtn" class="map-type-button">Normal</div>
          <div id="satelliteMapBtn" class="hidden map-type-button">
            Satellite
          </div>
        </div>
        <div ref="storeInfoPopup"></div>
      </div>
    </div>
  </section>
</template>
<script setup>
import { ContentLoader } from "vue-content-loader";
import Search2LineIcon from "~/assets/svg/heroicons/search-2-line.svg?component";
import Closemobile from "~/assets/svg/closemobile.svg?component";
import CountMarker from "@/assets/images/count-marker.png";
import EqualizerLineIcon from "~/assets/svg/heroicons/equalizer-line.svg?component";
import { createApp, render, createVNode, h } from "vue";
import StoreInfoWindow from "~/components/StoreInfoWindow";
import { useTranslation } from "i18next-vue";
import { useMainStore } from "@/store/index";

const mainStore = useMainStore();
const route = useRoute();
const { t } = useTranslation();
const nuxtApp = useNuxtApp();

const mapUi = ref(null);
const baiduMap = ref(null);
let map = reactive(null);
const isMobile = ref(false);
const autocomplete = ref(null);
const bounds = ref(null);
const markerClustererData = ref(null);
// search
const state = reactive({
  isMobileDrawer: false,
  distributorCategories: [],
});
let place = reactive({
  lat: null,
  lng: null,
});
const isEmpty = ref(false);
const searchQuery = ref("");
const distance = ref(50);
const distanceUnit = ref("km");
const services = ref([]);
const stores = ref([]);
const selectedStore = ref(null);
const markers = ref([]);
const isLoading = ref(true);
const isFetching = ref(false);
const infoWindows = ref([]);
const tabs = ref([t("common.all"), t("common.store"), t("common.service")]);
const isDropdownOpen = ref(false);
const activetab = ref("km");
const servicesList = ref([]);
const autocompleteInput = ref(null);
const storeInfoPopup = ref(null);
const markerIcon = ref(null);
const BMAP_ANCHOR_BOTTOM_RIGHT = ref(null);

onMounted(() => {
  if (!process.browser) {
    return;
  }

  if (window.innerWidth >= 768) {
    state.isMobileDrawer = false;
    isMobile.value = false;
  } else {
    isMobile.value = true;
  }

  nuxtApp.$loadBaiduMaps().then(async () => {
    // Bind Map
    map = new BMap.Map(mapUi.value); // Replace with your map container ID
    map.centerAndZoom(new BMap.Point(116.404, 39.915), 1); // Set initial center and zoom level

    map.setMaxZoom(22); // Set max zoom level
    map.addControl(new BMap.NavigationControl()); // Add navigation controls
    map.addControl(new BMap.ScaleControl()); // Add scale control
    map.addControl(new BMap.OverviewMapControl()); // Add overview map control
    map.addControl(new BMap.MapTypeControl()); // Add map type control
    map.addControl(new BMap.Control()); // Add custom controls if needed

    // Set additional map options
    map.setMinZoom(1);
    // Enable scroll wheel zoom

    // Add controls to the map
    addControls(map);
    addCustomMapControls(map);
    // Create a new BMap.Autocomplete instance
    let restriction = {};

    if (mainStore.country !== "int") {
      restriction = {
        region: mainStore.country, // Specify region restriction
      };
    }

    addGeoLocationButton();

    bounds.value = new BMap.Bounds(
      new BMap.Point(0, 0), // Southwest corner
      new BMap.Point(0, 0) // Northeast corner
    );

    // Bind place_changed event

    await nextTick();

    window.onresize = reportWindowSize;
    setCompanyCenter();
  });
});
function addCustomMapControls(map) {
  // Get the buttons
  const normalMapBtn = document.getElementById("normalMapBtn");
  const satelliteMapBtn = document.getElementById("satelliteMapBtn");

  // Add event listeners to the buttons
  normalMapBtn.addEventListener("click", () => {
    map.setMapType(window.BMAP_NORMAL_MAP); // Switch to normal map
    normalMapBtn.classList.add("hidden");
    satelliteMapBtn.classList.remove("hidden");
  });

  satelliteMapBtn.addEventListener("click", () => {
    map.setMapType(window.BMAP_SATELLITE_MAP); // Switch to satellite map
    satelliteMapBtn.classList.add("hidden");
    normalMapBtn.classList.remove("hidden");
  });

  // Initialize default state
  map.setMapType(window.BMAP_NORMAL_MAP);
  normalMapBtn.classList.add("hidden");
  satelliteMapBtn.classList.remove("hidden");
}

function onToggleDrawer() {
  state.isMobileDrawer = !state.isMobileDrawer;
}
function onOpenDrawer() {
  if (isMobile.value) {
    state.isMobileDrawer = true;
  }
  if (!autocomplete.value) {
    // Initialize autocomplete only once
    autocomplete.value = new BMap.Autocomplete({
      input: autocompleteInput.value,
      location: map.value,
    });

    autocomplete.value.addEventListener("onconfirm", (e) => {
      console.log("Confirm event triggered:", e);
      const place = e.item.value;
      searchQuery.value = place;
      searchPlace();
      // You can further process the selected place here
    });
  }
}
function onSwitchDropdown() {
  isDropdownOpen.value = !isDropdownOpen.value;
  if (isMobile.value) {
    state.isMobileDrawer = true;
  }
}

function addControls(map) {
  // Map Type Control
  const mapTypeControl = new window.BMap.MapTypeControl({
    mapTypes: [window.BMAP_NORMAL_MAP, window.BMAP_SATELLITE_MAP],
    anchor: window.BMAP_ANCHOR_BOTTOM_RIGHT,
  });
  map.addControl(mapTypeControl);

  // Custom Fullscreen Control
  function CustomFullscreenControl() {
    this.defaultAnchor = window.BMAP_ANCHOR_BOTTOM_RIGHT;
    this.defaultOffset = new window.BMap.Size(10, 50);
  }
  CustomFullscreenControl.prototype = new window.BMap.Control();

  CustomFullscreenControl.prototype.initialize = function (map) {
    const div = document.createElement("div");
    div.className = "custom-fullscreen";
    div.style.bottom = "10px";
    div.style.right = "10px";
    div.addEventListener("click", () => toggleFullscreen(map.getContainer()));
    map.getContainer().appendChild(div);
    return div;
  };
  const fullscreenControl = new CustomFullscreenControl();
  map.addControl(fullscreenControl);

  function toggleFullscreen(element) {
    if (!document.fullscreenElement) {
      element
        .requestFullscreen()
        .catch((err) =>
          alert(
            `Error attempting to enable fullscreen mode: ${err.message} (${err.name})`
          )
        );
    } else {
      document.exitFullscreen();
    }
  }

  // Navigation Control
  const navigationControl = new window.BMap.NavigationControl({
    type: window.BMAP_NAVIGATION_CONTROL_LARGE,
    anchor: window.BMAP_ANCHOR_BOTTOM_RIGHT,
    enableGeolocation: true,
  });
  map.addControl(navigationControl);

  // Custom Street View Control
  function CustomStreetViewControl() {
    this.defaultAnchor = window.BMAP_ANCHOR_BOTTOM_RIGHT;
    this.defaultOffset = new window.BMap.Size(10, 50);
  }
  CustomStreetViewControl.prototype = new window.BMap.Control();

  CustomStreetViewControl.prototype.initialize = function (map) {
    const div = document.createElement("div");
    div.className = "custom-streetview";
    div.onclick = () => {
      const panorama = new window.BMap.Panorama(map.getContainer());
      map.addOverlay(panorama);
      panorama.setPosition(map.getCenter());
      panorama.setPov({ heading: -40, pitch: 6 });
    };
    map.getContainer().appendChild(div);
    return div;
  };
  const streetViewControl = new CustomStreetViewControl();
  map.addControl(streetViewControl);

  // Scale Control
  const scaleControl = new window.BMap.ScaleControl({
    anchor: window.BMAP_ANCHOR_BOTTOM_RIGHT,
  });
  map.addControl(scaleControl);
}

function onSearch(e) {
  searchQuery.value = e.target.value;

  if (!searchQuery.value) {
    isEmpty.value = true;
    resetState();
  } else {
    isEmpty.value = false;
  }
}
function searchPlaceWithFill(data) {
  if (!searchQuery.value) {
    isEmpty.value = true;
    return;
  }
  isEmpty.value = false;
  searchPlace(data);
}
function away() {
  isEmpty.value = false;
  isDropdownOpen.value = false;
}
function addGeoLocationButton() {
  const locationButton = document.createElement("button");
  locationButton.textContent = "Locate Me"; // Optional: you can add text or an icon
  locationButton.className = "custom-map-control-button"; // Apply custom styles

  // Define a custom control for Baidu Maps
  const geoLocationControl = new BMap.Control({
    anchor: BMAP_ANCHOR_BOTTOM_RIGHT, // Position of the control
    offset: new BMap.Size(10, 10), // Offset from the map edge
    content: locationButton, // The content of the control
  });

  // Add the control to the map
  map.addControl(geoLocationControl);

  // Add click event listener to the button
  locationButton.addEventListener("click", () => {
    getCurrentPosition();
  });
}
function handleLocationError(browserHasGeolocation, infoWindow, pos) {
  infoWindow.setPosition(pos);
  infoWindow.setContent(
    browserHasGeolocation
      ? t("common.error_geolocation_service")
      : t("common.error_geolocation_not_support")
  );
  infoWindow.open(map);
}
function storeInfoCurrentTab(tab) {
  if (tab === "Store") {
    state.distributorCategories = ["point_of_sale"];
  } else if (tab === "Service") {
    state.distributorCategories = ["service_center"];
  } else if (tab === "All") {
    state.distributorCategories = [];
  }

  onOpenDrawer();
  isFetching.value = false;

  searchPlace({ use_distance: searchQuery.value.length > 0 });
}
function reportWindowSize(e) {
  if (window.innerWidth >= 768) {
    state.isMobileDrawer = false;
    isMobile.value = false;
  } else {
    isMobile.value = true;
  }
}

function activeTabMethod(currentTab) {
  activetab.value = currentTab;
  distanceUnit.value = activetab.value;
  if (!searchQuery.value) {
    isEmpty.value = true;
  } else {
    isEmpty.value = false;
  }
  searchPlace({ use_distance: searchQuery.value.length > 0 });
}
function toggleService(serviceKey) {
  const existsIndex = services.value.indexOf(serviceKey);
  if (existsIndex === -1) {
    services.value.push(serviceKey);
  } else {
    services.value.splice(existsIndex, 1);
  }

  searchPlace({ use_distance: searchQuery.value.length > 0 });
}
function setCompanyCenter() {
  // Infinite budget for Googe Maps API?
  const hasInfiniteBudget = "🤣";
  if (hasInfiniteBudget === "🤣") {
    place = {
      lat: parseFloat(mainStore.currentSite.country.country_lat),
      lng: parseFloat(mainStore.currentSite.country.country_long),
    };

    searchPlace({ use_distance: false });
  } else {
    // money-wise guy.
    setDefaultMapCenter();
  }
}
function isGeoEnabled() {
  if (!process.browser) {
    return false;
  }

  return "geolocation" in navigator;
}

function getCurrentPosition() {
  if (!isGeoEnabled()) {
    console.warn("Geolocation is not enabled.");
    return;
  }

  navigator.geolocation.getCurrentPosition(
    (position) => {
      // Initialize the Baidu Maps Geocoder
      const geocoder = new BMap.Geocoder();

      // Create a new LatLng object with the user's position
      const point = new BMap.Point(
        position.coords.longitude,
        position.coords.latitude
      );

      // Use Baidu Maps' Geocoder to get the address
      geocoder.getLocation(point, (result) => {
        if (result) {
          // Update the place and search query
          place = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          searchPlace();
          searchQuery.value = result.address; // result.address contains the formatted address
          // console.log(result.address)
        } else {
          console.error("Failed to get address from Baidu Maps");
        }
      });
    },
    (error) => {
      if (error.code === error.PERMISSION_DENIED) {
        setDefaultMapCenter();
      } else {
        console.error("Geolocation error:", error);
      }
    },
    {
      timeout: 70000,
      enableHighAccuracy: true,
      maximumAge: 75000,
    }
  );
}
function setDefaultMapCenter(coords, zoom = 5) {
  map.setCenter({
    lat: parseFloat(mainStore.currentSite.country.country_lat),
    lng: parseFloat(mainStore.currentSite.country.country_long),
  });

  map.setZoom(zoom);
}

function setMapCenter(place, zoom = 5) {
  const position = new BMap.Point(
    parseFloat(place.lng), // Longitude first for Baidu
    parseFloat(place.lat) // Latitude second for Baidu
  );

  if (!isNaN(position.lng) && !isNaN(position.lat)) {
    map.centerAndZoom(position, zoom); // Center and zoom the map
  }
}
async function addMarker(data) {
  const position = new BMap.Point(
    parseFloat(data.position.lng),
    parseFloat(data.position.lat)
  );

  if (isNaN(position.lng) || isNaN(position.lat)) {
    console.warn("☢️ wrong position");
    console.table(data);
    return;
  }

  const glyphImg = document.createElement("img");
  glyphImg.src = "/ellipse.png";
  glyphImg.width = 18;
  glyphImg.height = 18;

  // Create marker
  const marker = new BMap.Marker(position, {
    icon: new BMap.Icon(glyphImg.src, new BMap.Size(18, 18)),
  });

  map.addOverlay(marker);

  // Create InfoWindow
  const StoreInfoWindowInstance = createStoreInfoWindow(data);
  const infoWindow = new BMap.InfoWindow(StoreInfoWindowInstance, {
    width: 250,
    minWidth: 200,
    minHeight: 100,
    borderRadius: 50,
  });

  // Add to arrays
  markers.value.push(marker);
  infoWindows.value.push(infoWindow);

  marker.addEventListener("click", () => {
    goToMarker(markers.value.indexOf(marker));
  });
}
const context = getCurrentInstance()?.appContext;

function createStoreInfoWindow(data) {
  let el = null;

  let vNode = createVNode(StoreInfoWindow, {
    distance: data.distance,
    info: data.info,
    baidu: true,
    position: data.position,
    showDetail: true,
  });

  if (context) vNode.appContext = context;
  if (typeof document !== "undefined")
    render(vNode, (el = document.createElement("div")));

  return vNode.el;
}

function closeInfoWindows() {
  infoWindows.value.forEach((info, index) =>
    info.close(map, markers.value[index])
  );
}

function goToMarker(index) {
  // if (index === selectedStore.value) {
  //   return;
  // }

  // Ensure that the infoWindows and markers arrays are properly initialized
  if (!infoWindows.value || !markers.value) {
    console.error("infoWindows or markers are not initialized.");
    return;
  }

  // Validate index
  if (
    index < 0 ||
    index >= infoWindows.value.length ||
    index >= markers.value.length
  ) {
    console.warn("Invalid index:", index);
    console.warn("infoWindows:", infoWindows.value);
    console.warn("markers:", markers.value);
    return;
  }

  // Close any open info windows
  closeInfoWindows();
  state.isMobileDrawer = false;

  // Retrieve the marker and infoWindow at the given index
  const marker = markers.value[index];
  const infoWindow = infoWindows.value[index];

  if (marker && infoWindow) {
    // Open the info window at the marker's position
    map.openInfoWindow(infoWindow, marker.getPosition());

    // Center the map on the marker
    setMapCenter(marker.getPosition(), 15);

    // Update the selected store index
    selectedStore.value = index;
    document.querySelectorAll(".BMap_pop div").forEach((button, e) => {
      if (e == 3) {
        button.addEventListener("click", closeInfoWindows);
      }
    });
  } else {
    console.warn("Marker or InfoWindow is undefined at index", index);
  }
}
function resetData() {
  // Reset bounds for Baidu Maps
  // bounds.value = new BMap.Bounds(
  //   new BMap.Point(-180, -90), // Southwest corner
  //   new BMap.Point(180, 90)    // Northeast corner
  // );

  // Clear info windows
  closeInfoWindows(); // Ensure this function is defined
  infoWindows.value = [];

  // Clear marker clusterer data if it exists
  if (markerClustererData.value) {
    markerClustererData.value.clearMarkers();
  }

  // Clear markers from the map
  if (markers.value && markers.value.length > 0) {
    markers.value.forEach((marker) => {
      if (marker instanceof BMap.Marker) {
        map.removeOverlay(marker); // Remove the marker using removeOverlay
        console.log("All markers removed from the map.");
      } else {
        console.warn("Invalid marker:", marker);
      }
    });
    markers.value.length = 0; // Clear the markers array
    console.log("All markers removed from the map.");
  }

  // Reset stores
  stores.value = [];
  selectedStore.value = null;
  map.clearOverlays();

  // Optionally reset the map view
  // if (map) {
  //   map.setViewport(bounds.value); // Adjust the map to fit the new bounds
  //   console.log("Map view reset to default bounds.");
  // }

  console.log("Data reset: bounds set, info windows cleared, markers removed, stores reset.");
}

function resetState() {
  searchQuery.value = "";
  setCompanyCenter();
}
async function searchPlace(params) {
  resetData();

  try {
    isLoading.value = true;
    isFetching.value = true;

    // Fetch services list
    const res = await useAPI("/api/front/stores/services");
    servicesList.value = res.data.value;

    // Fetch stores data
    const { data } = await useAPI("/api/front/stores/v2", {
      query: {
        distance: distance.value,
        distance_unit: distanceUnit.value,
        lat: place.lat,
        lng: place.lng,
        "services[]": services.value,
        use_distance: true,
        "distributor_categories[]": state.distributorCategories,
        ...params,
      },
    });
    stores.value = data.value.data;
    isLoading.value = false;
    // Center the map on the place
    setMapCenter(place);

    if (process.client && stores.value.length > 0) {
      stores.value.forEach((store) => setTimeout(() => addMarker(store), 5));

      // Use a timeout to ensure markers are added before fitting bounds
      setTimeout(() => {
        //  console.log(markers.length); // Check the number of markers

        if (markers.value.length > 0) {
          const styles = [
            {
              url: CountMarker,
              size: new window.BMap.Size(50, 50), // Size of the marker icon
              textColor: "#fff", // Text color for the cluster label
              textSize: 13, // Text size for the cluster label
            },
          ];

          // Initialize the MarkerClusterer with the map, markers, and styles
          const markerClusterer = new window.BMapLib.MarkerClusterer(map, {
            markers: markers.value,
            styles: styles,
          });
          // const bounds = markerClusterer.value.getBounds();
          // if (bounds) {
          //   map.setViewport(bounds); // Automatically adjusts the map to fit the cluster bounds
          // }
        }
      }, 1000);
    }else{
      map.clearOverlays();
    }
  } catch (error) {
    isLoading.value = false;
    isFetching.value = false;
    console.error(error);
  }
}
useHead({
  title: t("common.stores_and_inventories"),
});
</script>

<style lang="postcss">
.custom-clustericon {
  background: var(--cluster-color);
  @apply text-white rounded-full font-bold text-base flex items-center;
}

.custom-clustericon::before,
.custom-clustericon::after {
  background: var(--cluster-color);
  content: "";
  @apply block absolute w-full h-full transform  -translate-y-1/2 top-1/2 start-1/2 opacity-20 rounded-full;

  --tw-translate-x: -50%;
}

[dir="rtl"] .custom-clustericon::before,
[dir="rtl"] .custom-clustericon::after {
  --tw-translate-x: 50%;
}

.custom-clustericon::before {
  @apply p-7;
}

.custom-clustericon::after {
  @apply p-9;
}

.custom-clustericon-1 {
  --cluster-color: #00a2d3;
}

.custom-clustericon-2 {
  --cluster-color: #ff9b00;
}

.custom-clustericon-3 {
  --cluster-color: #ff6969;
}

.tangram-suggestion-main {
  @apply z-50;
}
.BMap_pop .BMap_bottom {
  overflow: visible !important;
}

.BMap_pop .BMap_bottom::after {
  content: "";
  width: 0;
  height: 0;
  border-left: 12px solid transparent; /* Width of the arrow */
  border-right: 12px solid transparent; /* Width of the arrow */
  border-top: 12px solid white; /* Color of the arrow */
  position: absolute;
  bottom: -12px; /* Position the arrow just below the InfoWindow */
  left: 42%; /* Center horizontally */
  transform: translateX(-50%); /* Adjust centering */
  z-index: 1000; /* Ensure the arrow is on top */
}
.BMap_pop > div:nth-child(3) > div:hover::after {
  opacity: 1; /* Opacity on hover */
}
.custom-cluster {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #00a2d3; /* Main color */
  color: #fff;
  line-height: 40px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  display: inline-block;
  z-index: 3;
}

.custom-cluster::before,
.custom-cluster::after {
  content: "";
  position: absolute;
  border-radius: 50%;
  z-index: -1;
}

.custom-cluster:hover {
  background-color: #00a2d3;
}
.BMap_pop > div:nth-child(1) {
  border-top-left-radius: 15px;
}
.BMap_pop > div:nth-child(3) {
  border-top-right-radius: 15px;
}
.BMap_pop > div:nth-child(3) > div {
  height: 40 !important;
}
.BMap_pop > div:nth-child(3) > div::after {
  content: "";
  display: block;
  width: 22px; /* Width of the close button */
  height: 22px; /* Height of the close button */
  background: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20d%3D%22M19%206.41L17.59%205%2012%2010.59%206.41%205%205%206.41%2010.59%2012%205%2017.59%206.41%2019%2012%2013.41%2017.59%2019%2019%2017.59%2013.41%2012z%22/%3E%3Cpath%20d%3D%22M0%200h24v24H0z%22%20fill%3D%22none%22/%3E%3C/svg%3E")
    no-repeat center center;

  position: absolute;
  top: 6px;
  right: 7px;
  cursor: pointer;
  opacity: 0.5; /* Default opacity */
  transition: opacity 0.3s ease; /* Smooth transition */
}
.BMap_pop > div:nth-child(5) {
  border-bottom-left-radius: 15px;
}
.BMap_pop > div:nth-child(7) {
  border-bottom-right-radius: 15px;
}
.BMap_pop div {
  border-color: white !important;
}
.BMap_pop > div:nth-child(8) img {
  display: none;
}
.BMap_pop > div:nth-child(8) {
  box-sizing: content-box;
  left: 94px !important;
  top: 249px !important;
  width: 10px !important;
  height: 0px !important;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  border: solid black;
  border-width: 0 30px 30px 0;
  display: inline-block;
  padding: 3px;
}
.BMap_stdMpZoom .BMap_button {
  width: 22px !important;
  height: 21px !important;
  left: 12px !important;
  overflow: hidden !important;
  background-repeat: no-repeat !important;
  z-index: 10 !important;
  background-position: inherit;
  background-color: white !important;
  padding: 20px !important;
}
.BMap_stdMpZoomIn {
  background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2018%2018%22%3E%3Cpath%20fill%3D%22%23666%22%20d%3D%22M18%207h-7V0H7v7H0v4h7v7h4v-7h7z%22/%3E%3C/svg%3E) !important;
  background-position: center !important;
  height: 18px !important;
  width: 18px !important;
  background-size: 18px !important;
  background-repeat: no-repeat !important;
  border-bottom: 1px solid #ccc;
}
.BMap_stdMpZoomOut {
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2018%2018%22%3E%3Cpath%20fill%3D%22%23666%22%20d%3D%22M0%207h18v4H0V7z%22/%3E%3C/svg%3E") !important;
  top: 40px !important;
  background-position: center !important;
  width: 18px !important;
  height: 18px !important;
  background-repeat: no-repeat !important;
  background-size: 20px !important;
}

@media (max-width: 600px) {
  .BMap_stdMpType4 .BMap_stdMpZoom .BMap_button {
    display: contents !important;
  }
  /* Mobile phones */
  .BMap_stdMpZoom {
    right: 10px; /* Ensure consistent positioning */
    top: -60px !important; /* Remove top positioning for mobile */
  }
}
@media (min-width: 969px) {
  .BMap_stdMpType4 .BMap_stdMpZoom .BMap_button {
    display: contents !important;
  }
  .BMap_stdMpZoom {
    top: -80px !important; /* Remove top positioning for mobile */
    right: 10px; /* Ensure consistent positioning */
  }
}
@media (max-width: 968px) {
  /* Mobile phones */
  .BMap_stdMpType4 .BMap_stdMpZoom .BMap_button {
    display: contents !important;
  }
  .BMap_stdMpZoom {
    right: 10px; /* Ensure consistent positioning */
    top: -80px !important; /* Remove top positioning for mobile */
  }
}
.BMap_stdMpSlider {
  height: 0px !important;
  display: none;
}
.BMap_stdMpPan {
  display: none !important;
}
.custom-fullscreen {
  width: 40px;
  height: 39px;
  background: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2018%2018%22%3E%3Cpath%20fill%3D%22%23666%22%20d%3D%22M0%200v6h2V2h4V0H0zm16%200h-4v2h4v4h2V0h-2zm0%2016h-4v2h6v-6h-2v4zM2%2012H0v6h6v-2H2v-4z%22/%3E%3C/svg%3E)
    no-repeat center center;
  background-size: 18px;
  cursor: pointer;
  position: absolute !important;
  z-index: 3333 !important;
  background-color: white;
  bottom: 11px !important;
  right: 20px !important;
}

.fullscreen-map {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  z-index: 10000 !important;
}
#zoomer + div {
  display: none !important;
}
.fullscreen-map {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  z-index: 10000 !important;
}
#map + div {
  position: absolute;
  /* top: 10px; */
  /* left: 10px; */
  z-index: 1000;
  border: none;
  right: 80px;
  bottom: 10px !important;
}
.map-type-button {
  padding: 8px 15px;
  background-color: white;
  cursor: pointer;
  border-radius: 3px;
  font-size: large;
  width: 120px; /* Example fixed width */
  max-width: 100%;
  text-align: center;
}

.map-type-button.hidden {
  display: none;
}
.BMap_geolocationIconBackground {
  background: none !important;
}
.BMap_geolocationIcon {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzUxNzFfMTg5NTYpIj4KPHBhdGggZD0iTTEuNjE5NzQgNy43NjI2MUMxLjE4NDc0IDcuNjE3NjEgMS4xODA1NyA3LjM4MzQ1IDEuNjI4MDcgNy4yMzQyOEwxNy41MzM5IDEuOTMyNjFDMTcuOTc0NyAxLjc4NTk1IDE4LjIyNzIgMi4wMzI2MiAxOC4xMDM5IDIuNDY0MjhMMTMuNTU4OSAxOC4zNjkzQzEzLjQzMzkgMTguODEwMSAxMy4xNzk3IDE4LjgyNTEgMTIuOTkzMSAxOC40MDY4TDkuOTk4MDcgMTEuNjY2OEwxNC45OTgxIDUuMDAwMTFMOC4zMzE0MSAxMC4wMDAxTDEuNjE5NzQgNy43NjI2MVoiIGZpbGw9IiM3NDc0NzQiLz4KPC9nPgo8ZGVmcz4KPGNsaXBQYXRoIGlkPSJjbGlwMF81MTcxXzE4OTU2Ij4KPHJlY3Qgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIiBmaWxsPSJ3aGl0ZSIvPgo8L2NsaXBQYXRoPgo8L2RlZnM+Cjwvc3ZnPgo=") !important;
  background-position: center !important;
  width: 20px !important;
  height: 20px !important;
  background-size: 18px !important;
  background-color: white !important;
  padding: 20px;
  position: absolute !important;
  bottom: 120px !important;
  left: -7px !important;
}
.BMap_stdMpGeolocation {
  margin-top: 0px !important;
}
.BMap_scaleCtrl {
  display: none !important;
}
.baidu-map div {
  background-position: center !important;
  background-repeat: no-repeat !important;
}
.custom-streetview {
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzUxNzFfMTg5NTEpIj4KPHBhdGggZD0iTTEuNjY3OTcgNC4xNjY3NUw3LjUwMTMgMS42NjY3NUwxMi41MDEzIDQuMTY2NzVMMTcuNzUzOCAxLjkxNTkxQzE3LjgxNzIgMS44ODg3MyAxNy44ODY0IDEuODc3NzIgMTcuOTU1MSAxLjg4Mzg3QzE4LjAyMzggMS44OTAwMSAxOC4wODk5IDEuOTEzMTIgMTguMTQ3NSAxLjk1MTEyQzE4LjIwNTEgMS45ODkxMiAxOC4yNTIzIDIuMDQwODIgMTguMjg1IDIuMTAxNThDMTguMzE3NiAyLjE2MjM0IDE4LjMzNDcgMi4yMzAyNiAxOC4zMzQ2IDIuMjk5MjVWMTUuODMzNEwxMi41MDEzIDE4LjMzMzRMNy41MDEzIDE1LjgzMzRMMi4yNDg4IDE4LjA4NDJDMi4xODU0IDE4LjExMTQgMi4xMTYyMyAxOC4xMjI0IDIuMDQ3NTIgMTguMTE2M0MxLjk3ODgxIDE4LjExMDIgMS45MTI3IDE4LjA4NyAxLjg1NTEyIDE4LjA0OUMxLjc5NzU1IDE4LjAxMSAxLjc1MDMxIDE3Ljk1OTMgMS43MTc2NCAxNy44OTg2QzEuNjg0OTggMTcuODM3OCAxLjY2NzkxIDE3Ljc2OTkgMS42Njc5NyAxNy43MDA5VjQuMTY2NzVaTTEyLjUwMTMgMTYuNDcwMVY1Ljk4MDA4TDEyLjQ0NzEgNi4wMDM0Mkw3LjUwMTMgMy41MzAwOFYxNC4wMjAxTDcuNTU1NDcgMTMuOTk2N0wxMi41MDEzIDE2LjQ3MDFaIiBmaWxsPSIjNzQ3NDc0Ii8+CjwvZz4KPGRlZnM+CjxjbGlwUGF0aCBpZD0iY2xpcDBfNTE3MV8xODk1MSI+CjxyZWN0IHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgZmlsbD0id2hpdGUiLz4KPC9jbGlwUGF0aD4KPC9kZWZzPgo8L3N2Zz4K") !important;
  width: 48px;
  height: 40px;
  inset: auto 230px 10px auto !important;
  background-color: white;
  background-position: center;
  border: none;
  padding: 20px;
}
.custom-clustericon {
  background: var(--cluster-color);
  @apply text-white rounded-full font-bold text-base flex items-center;
}

.custom-clustericon::before,
.custom-clustericon::after {
  background: var(--cluster-color);
  content: "";
  @apply block absolute w-full h-full transform  -translate-y-1/2 top-1/2 start-1/2 opacity-20 rounded-full;

  --tw-translate-x: -50%;
}

[dir="rtl"] .custom-clustericon::before,
[dir="rtl"] .custom-clustericon::after {
  --tw-translate-x: 50%;
}

.custom-clustericon::before {
  @apply p-7;
}

.custom-clustericon::after {
  @apply p-9;
}

.tangram-suggestion-main {
  @apply z-50;
}
</style>