<template>
  <section class="bg-black work-with-us">
    <!--Desktop Hero Section -->
    <div
      class="work-with-us-hero-bg-section relative md:block hidden w-full overflow-hidden md:bg-center bg-no-repeat bg-right md:bg-contain h-[632px] sm:h-[600px] lg:h-[742px] xl:block"
    >
      <div class="h-[480px] md:h-[600px] lg:h-[742px]">
        <div class="container pt-[130px] lg:pt-[164px]">
          <div
            class="border-b-4 ml-0.5 md:border-b-[6px] skew-x-[-25deg] w-[106px]"
            style="border-color: #13b973"
          />
          <h1
            class="text-[32px] md:text-5xl flex xl:text-[68px] italic font-myriad-pro font-semibold xl:tracking-normal leading-[38px] md:leading-[3rem] xl:leading-[58px] text-start text-white mt-6 xl:mt-[41px] mb-0"
          >
            {{ $t("common.work_with_us_page.potential_heading") }}&nbsp;
            <span class="text-primary">
              {{ $t("common.work_with_us_page.potential_title") }}
            </span>
          </h1>
          <p
            class="brand-name-style text-[15px] md:text-base w-[299px] md:w-[440px] xl:w-[540px] italic font-normal font-myriad-pro !leading-[24px] text-start text-white pt-[10px] md:pt-[24px]"
            v-html="
              $t('common.work_with_us_page.potential_description', {
                brand: 'Benelli ',
                interpolation: { prefix: '[:', suffix: ']' },
              })
            "
          ></p>
        </div>
      </div>
    </div>

    <!-- Mobile Version Hero Section-->
    <div class="block lg:hidden md:hidden">
      <div
        class="relative"
        data-aos="fade-left"
        data-aos-delay="20"
        data-aos-offset="100"
      >
        <img
          class="relative w-full h-[480px] object-cover object-top"
          src="@/assets/images/work-with-us/mobile-version-unlock-potential.png"
          alt=""
        />
      </div>
      <div
        class="flex bg-[#191919] items-center justify-start order-2 px-4 py-[60px] overflow-hidden md:w-1/2"
        data-aos="fade-left"
        data-aos-delay="100"
      >
        <div class="w-auto">
          <div
            class="border-b-4 w-[67px] border-primary skew-x-[-25deg] flex justify-start items-start text-start h-1 lg:h-[6px] mb-[16px]"
          />
          <h1
            class="text-[34px] font-myriad-pro italic font-semibold w-[345px] sm:w-full tracking-[-2%] leading-[38px] text-start text-white"
          >
            {{ $t("common.work_with_us_page.potential_heading") }}
            <span class="text-primary">
              {{ $t("common.work_with_us_page.potential_title") }}</span
            >
          </h1>
          <p
            class="text-[15px] xl:text-[20px] italic xl:leading-normal mt-[12px] text-white leading-[24px] font-myriad-pro font-normal"
            v-html="
              $t('common.work_with_us_page.potential_description', {
                brand: 'Benelli ',
                interpolation: { prefix: '[:', suffix: ']' },
              })
            "
          ></p>
        </div>
      </div>
    </div>

    <!-- Form Section -->
    <div class="py-[60px] md:py-[100px] mt-0 md:container">
      <div class="px-4 lg:px-0">
        <div class="mx-auto">
          <h2
            class="text-[26px] leading-[34px] text-center italic md:text-left sm:text-2xl md:text-[33px] uppercase font-myriad-pro font-semibold md:font-OPPO-sans-heavy text-primary"
          >
            {{ $t("common.work_with_us_page.work_together") }}
          </h2>

          <form
            id="work_with_us_form"
            action="#"
            method="POST"
            @submit.prevent="onSubmit"
            class="grid grid-cols-1 mt-9 gap-y-[28px] sm:grid-cols-2 sm:gap-x-6"
          >
            <!-- <div class="sm:col-span-2">
              <label for="work_with_us__subject" class="block text-sm font-medium text-white" v-text="$t('common.subject')" />
              <div class="mt-1">
                <select
                  id="work_with_us__subject"
                  v-model="form.subject"
                  type="text"
                  name="subject"
                  class="block w-full px-4 py-3 border-gray-300 bg-[#FFFFFF] bg-opacity-10 rounded-md shadow-sm"
                  :class="{'is-invalid': errorsObj && errorsObj.subject}"
                >
                  <option v-for="(subject, subjectIndex) in subjects" :key="subjectIndex" :value="$t(`common.${subject}`)" v-text="$t(`common.${subject}`)" />
                </select>
              </div>
            </div> -->

            <div>
              <label
                for="work_with_us__given_name"
                class="block text-[11px] text-[#DDDDDD] uppercase font-myriad-pro font-semibold leading-[20px]"
                v-text="$t('common.given_name')"
              />
              <div class="mt-1">
                <input
                  id="work_with_us__given_name"
                  v-model="form.given_name"
                  data-cons-subject="first_name"
                  type="text"
                  name="given_name"
                  :placeholder="$t('common.placeholder')"
                  autocomplete="given-name"
                  :class="[
                    { 'is-invalid': errorsObj && errorsObj.given_name },
                    'mt-1 w-full rounded-[10px] h-12  text-sm py-[14px] bg-[#FFFFFF] bg-opacity-10 px-5 focus:ring-primary-500 focus:border-primary-500 border border-[#DBDBDB] border-opacity-30 font-myriad-pro font-normal  text-white',
                  ]"
                  @input="clearValidation('given_name')"
                />
                <p
                  v-if="errorsObj && errorsObj.given_name"
                  class="mt-1 text-sm text-red-400"
                  v-text="errorsObj.given_name[0]"
                />
              </div>
            </div>
            <div>
              <label
                for="work_with_us_family_name"
                class="block text-[11px] text-[#DDDDDD] uppercase font-myriad-pro font-semibold leading-[20px]"
                v-text="$t('common.family_name')"
              />
              <div class="mt-1">
                <input
                  id="work_with_us_family_name"
                  :placeholder="$t('common.placeholder')"
                  v-model="form.family_name"
                  type="text"
                  data-cons-subject="last_name"
                  name="family_name"
                  autocomplete="family-name"
                  :class="[
                    { 'is-invalid': errorsObj && errorsObj.family_name },
                    'mt-1 w-full rounded-[10px] h-12  text-sm py-[14px] bg-[#FFFFFF] bg-opacity-10 px-5 focus:ring-primary-500 focus:border-primary-500 border border-[#DBDBDB] border-opacity-30 font-myriad-pro font-bold font-normal  text-white',
                  ]"
                  @input="clearValidation('family_name')"
                />
                <p
                  v-if="errorsObj && errorsObj.family_name"
                  class="mt-1 text-sm text-red-400"
                  v-text="errorsObj.family_name[0]"
                />
              </div>
            </div>
            <div v-if="mainStore.country == 'int'">
              <label
                for="work_with_us_country"
                class="block text-[11px] text-[#DDDDDD] uppercase font-myriad-pro font-semibold leading-[20px]"
                v-text="$t('common.country')"
              />
              <div class="mt-1">
                <vue-select
                  ref="select"
                  v-model="form.country"
                  :options="countries"
                  label="name"
                  class="vue-select-chooser"
                  :placeholder="$t('common.placeholder')"
                  :class="{
                    'is-invalid': errorsObj && errorsObj.id,
                  }"
                  :reduce="(country) => country.id"
                  @update:modelValue="clearValidation('country')"
                >
                  <template #option="option">
                    <div class="flex items-center text">
                      <span class="text-gray-500">{{ option.flag }}</span>
                      <span class="ml-2">{{ option.name }}</span>
                    </div>
                  </template>
                </vue-select>
                <p
                  v-if="errorsObj && errorsObj.country"
                  class="mt-1 text-sm text-red-400"
                  v-text="errorsObj.country[0]"
                />
              </div>
            </div>
            <div :class="mainStore.country == 'int' ? '' : 'sm:col-span-2'">
              <label
                for="work_with_us_date_of_birth"
                class="block text-[11px] text-[#DDDDDD] uppercase font-myriad-pro font-semibold leading-[20px]"
                v-text="$t('common.date_of_birth')"
              />
              <div class="sm:col-span-2">
                <input
                  v-model="form.date_of_birth"
                  :placeholder="$t('common.placeholder')"
                  type="date"
                  :class="[
                    { 'is-invalid': errorsObj && errorsObj.date_of_birth },
                    form.date_of_birth ? 'has-value' : '',
                    'mt-1 w-full rounded-[10px] h-12 placeholder:text-white text-sm py-[14px] bg-[#FFFFFF] bg-opacity-10 px-5 focus:ring-primary-500 focus:border-primary-500 border border-[#DBDBDB] border-opacity-30 font-myriad-pro font-normal text-white',
                  ]"
                  id="work_with_us_date_of_birth"
                  @input="clearValidation('date_of_birth')"
                />
                <p
                  v-if="errorsObj && errorsObj.date_of_birth"
                  class="mt-1 text-sm text-red-400"
                  v-text="errorsObj.date_of_birth[0]"
                />
              </div>
            </div>
            <div class="sm:col-span-2">
              <label
                for="work_with_us__email"
                class="block text-[11px] text-[#DDDDDD] uppercase font-myriad-pro font-semibold leading-[20px]"
                v-text="$t('common.email')"
              />
              <div class="mt-1">
                <input
                  id="work_with_us__email"
                  :placeholder="$t('common.placeholder')"
                  v-model="form.email"
                  data-cons-subject="email"
                  name="email"
                  type="email"
                  autocomplete="email"
                  :class="[
                    { 'is-invalid': errorsObj && errorsObj.email },
                    'mt-1 w-full rounded-[10px] h-12  text-sm py-[14px] bg-[#FFFFFF] bg-opacity-10 px-5 focus:ring-primary-500 focus:border-primary-500 border border-[#DBDBDB] border-opacity-30 font-myriad-pro font-bold font-normal  text-white',
                  ]"
                  @input="clearValidation('email')"
                />
                <p
                  v-if="errorsObj && errorsObj.email"
                  class="mt-1 text-sm text-red-400"
                  v-text="errorsObj.email[0]"
                />
              </div>
            </div>
            <div class="sm:col-span-2">
              <label
                for="work_with_us_phone"
                class="block text-[11px] text-[#DDDDDD] uppercase font-myriad-pro font-semibold leading-[20px]"
                v-text="$t('common.phone')"
              />
              <div class="relative mt-1 rounded-md shadow-sm">
                <client-only>
                  <VueTelInput
                    id="phone"
                    v-model="form.phone"
                    input-id="work_with_us_phone"
                    mode="international"
                    placeholder="(201) 555-4433"
                    autocomplete="phone"
                    :default-country="mainStore.country"
                    :class="[
                      ' text-white  phone',
                      errorsObj && errorsObj.phone ? 'is-invalid' : '',
                    ]"
                    :input-options="options"
                    @input="clearValidation('phone')"
                  />
                </client-only>
                <p
                  v-if="errorsObj && errorsObj.phone"
                  class="mt-1 text-sm text-red-400"
                  v-text="errorsObj.phone[0]"
                />
              </div>
            </div>
            <div>
              <label
                class="block text-base font-normal text-white uppercase font-myriad-pro"
                v-text="$t('common.cv')"
              />
              <div class="pb-3 mt-2">
                <input
                  ref="fileInput"
                  type="file"
                  accept=".doc, .docx, .pdf"
                  class="hidden text-white cursor-pointers"
                  @change="parseUpload"
                />

                <div
                  class="flex gap-[12px] font-myriad-pro font-medium items-center"
                >
                  <button
                    type="button"
                    class="text-sm md:text-base uppercase bg-primary pt-1.5 rounded-[3px] text-white py-1 px-[14px]"
                    @click="openFilePicker"
                  >
                    Choose File
                  </button>
                  <span v-if="form.cv" class="text-white">
                    {{ form.cv && form.cv.name }}</span
                  >
                  <span
                    v-else
                    class="text-base font-light text-gray-500 pt-0.5 font-myriad-pro"
                  >
                    No file chosen
                  </span>
                </div>
                <p
                  v-if="errorsObj && errorsObj.cv"
                  class="mt-1 text-sm text-red-400"
                  v-text="errorsObj.cv[0]"
                />
              </div>
            </div>
            <!-- <div class="sm:col-span-2">
              <label
                for="work_with_us_message"
                class="block text-sm font-medium text-white"
                v-text="$t('common.message')"
              />
              <div class="relative mt-1">
                <textarea
                  id="work_with_us_message"
                  v-model="form.message"
                  name="message"
                  rows="4"
                  maxlength="1000"
                  class="block w-full px-4 py-3 border border-gray-300 bg-[#FFFFFF] bg-opacity-10 rounded-md shadow-sm min-h-40 lg:min-h-32 max-h-64"
                  :class="{ 'is-invalid': errorsObj && errorsObj.message }"
                />
                <span
                  class="absolute h-6 text-xs leading-6 text-gray-600 end-1 -bottom-6"
                  v-text="charactersLeft"
                />
              </div>
            </div> -->
            <div class="sm:col-span-2">
              <label
                class="w-full text-sm italic tracking-[-0.18px] text-white border border-transparent cursor-pointer md:text-lg contact--checkbox"
              >
                <input
                  id="contact_tos"
                  v-model="form.accept_terms"
                  type="checkbox"
                  class="me-3.5 cursor-pointer pt-0.5 relative text-primary-500 bg-white rounded border-gray-300 focus:ring-primary-500 focus:ring-2 mb-[.1rem]"
                  data-cons-preference="terms-and-conditions"
                  @input="clearValidation('accept_terms')"
                />
                <!-- eslint-disable vue/no-v-html -->
                <span
                  class="terms contact__checkmark font-myriad-pro-condensed fot text-[#FFFFFF]"
                  v-html="
                    mainStore.country === 'cn' || mainStore.country === 'it'
                      ? $t(
                          'common.i_agree_with_the_terms_and_conditions'
                        ).replace(
                          'legal',
                          $i18nPath(mainStore.pageLinks['terms'])
                        )
                      : $t('common.i_agree_with_privacy_policy').replace(
                          'privacy_policy',
                          $i18nPath(mainStore.pageLinks['privacy-policy'])
                        )
                  "
                />
                <!-- eslint-enable -->
              </label>
              <p
                v-if="errorsObj && errorsObj.accept_terms"
                class="mt-1 text-sm text-red-400"
                v-text="errorsObj.accept_terms[0]"
              />
            </div>

            <div class="justify-center hidden md:justify-start">
              <vue-hcaptcha
                ref="invisibleHcaptcha"
                size="invisible"
                :sitekey="$config.HCAPTCHA_SITE_KEY"
                theme="dark"
                :language="mainStore.locale"
                @verify="onVerify"
                @expired="onExpire"
                @closed="onExpire"
                @challengeExpired="onExpire"
                @error="onError"
              />
            </div>

            <div class="sm:col-span-2">
              <Btn
                id="contact_submit"
                type="submit"
                size="lg"
                block
                color="primary"
                :disabled="isSending"
              >
                <span
                  class="flex items-center justify-center font-myriad-pro text-white font-normal text-[18px] leading-[26px] space-x-2"
                >
                  <Spinner v-if="isSending" class="h-5 animate-spin" />
                  <span
                    class="font-normal text-white uppercase font-myriad-pro"
                  >
                    {{ $t("common.submit") }}
                  </span>
                </span>
              </Btn>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { VueTelInput } from "vue-tel-input";
import VueHcaptcha from "@hcaptcha/vue3-hcaptcha";
import SpinnerIcon from "~/assets/svg/spinner.svg?component";
import { useMainStore } from "@/store/index";
import { useTranslation } from "i18next-vue";
// import { useAsyncData } from "#app";
import { useLocaleStore } from "@/store/locales";

const mainStore = useMainStore();
const localeStore = useLocaleStore();
const nuxtApp = useNuxtApp();
const { t } = useTranslation();
const $config = nuxtApp.$config.public;
const invisibleHcaptcha = ref(null);
const fileInput = ref(null);

const formFields = reactive({
  cv: "",
  country: "",
  date_of_birth: "",
  given_name: "",
  family_name: "",
  email: "",
  phone: "",
  accept_terms: "",
});
const countries = ref([]);

try {
  const { data } = await useAPI("api/front/countries");
  countries.value = data._rawValue.data;
} catch (e) {
  error({ statusCode: 404 });
}

let form = reactive(Object.assign({}, formFields));
let errorsObj = ref();
let token = ref(null);
const isSending = ref(false);
let options = reactive({
  placeholder: t("common.phone_number_placeholder"),
});

const charactersLeft = computed(() => {
  const char = form.message.length;
  const limit = 1000;

  return limit - char + " / " + limit;
});

const getOptionKey = (option) => {
  if (option) {
    return option.id;
  } else option;
};

function clearValidation(field) {
  switch (field) {
    case "cv":
      errorsObj.cv = null;
      break;
    case "date_of_birth":
      errorsObj.date_of_birth = null;
      break;
    case "country":
      errorsObj.country = null;
      break;
    case "phone":
      errorsObj.phone = null;
      break;
    case "given_name":
      errorsObj.given_name = null;
      break;
    case "family_name":
      errorsObj.family_name = null;
      break;
    case "accept_terms":
      errorsObj.accept_terms = null;
      break;

    default:
      break;
  }
}

function openFilePicker() {
  // Trigger the click event of the file input
  // $refs.fileInput.click();

  if (fileInput.value) {
    fileInput.value.click();
  }
}

function parseUpload(event) {
  if (!event.target.files) {
    return;
  }
  form.cv = event.target.files[0];
  clearValidation("cv");
}

function onVerify(token, ekey) {
  const defaultCountry = localeStore.defaultLocales.find(
    (_v) => _v.slug === mainStore.country
  );
  form.company_id = defaultCountry ? defaultCountry.id : "";

  const formData = new FormData();
  formData.append("cv", form.cv);
  formData.append("date_of_birth", form.date_of_birth);
  formData.append("company_id", form.company_id);
  formData.append("country", form.country);
  formData.append("phone", form.phone);
  formData.append("email", form.email);
  formData.append("given_name", form.given_name);
  formData.append("family_name", form.family_name);
  formData.append("accept_terms", form.accept_terms);
  formData.append("h-captcha-response", token);

  useAPI("/api/front/work-with-us", {
    method: "POST",
    body: formData,
  })
    .then((response) => {
      if (response.error.value) {
        errorsObj = response.error.value.cause._data.errors;
        nuxtApp.$toast.error(response.error.value.cause._data.message);
        isSending.value = false;
      } else {
        form = Object.assign({}, formFields);

        nuxtApp.$toast.success("common.work_with_us_page_thanks");

        isSending.value = false;

        window._iub.cons_instructions.push([
          "submit",
          {
            writeOnLocalStorage: false,
            form: {
              selector: document.getElementById("work_with_us_form"),
            },
            consent: {
              legal_notices: [
                {
                  identifier: "privacy_policy",
                },
                {
                  identifier: "cookie_policy",
                },
                {
                  identifier: "term",
                },
              ],
            },
          },
          {
            success(response) {
              isSending.value = false;
              // console.log(response)
            },
            error(response) {
              isSending.value = false;
              console.warning(response);
            },
          },
        ]);
      }
    })
    .catch((error) => {
      console.error(error);
      isSending.value = false;
    });
}

function onExpire() {
  nuxtApp.$toast.error("Captcha expired");
  isSending.value = false;
}

function onError() {
  isSending.value = false;
}

function onSubmit() {
  if (isSending.value) {
    return;
  }

  isSending.value = true;
  errorsObj.value = {};

  if (invisibleHcaptcha.value) {
    invisibleHcaptcha.value.execute();
  }
}

// useHead({
//   title: t("common.work_with_us"),
// });
</script>

<style lang="postcss">
@import "vue-tel-input/vue-tel-input.css";

.work-with-us-hero-bg-section {
  background-image: url("@/assets/images/work-with-us/unlock-potential-hero-banner-img.png");
  background-repeat: no-repeat;
  background-position: center center;
  @apply md:bg-cover;
}

.work-with-us .vti__dropdown-list.below {
  top: 47px;
}

.work-with-us .contact--checkbox {
  .contact__checkmark {
    height: 17px;
    width: 17px;
    top: 0.2rem;
    left: 0;
    border-radius: 5px;

    &::after {
      content: "";

      @apply absolute hidden;
      top: 3px;
      left: 6px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}

.work-with-us #phone {
  @apply h-12 w-full border-[#DBDBDB] border-opacity-30 opacity-100 rounded-[10px] text-white placeholder-current !important;
}

.work-with-us .terms a:hover {
  @apply !text-primary-500;
}

.work-with-us .terms a {
  @apply !text-white text-lg font-normal leading-7;
}

.work-with-us .vue-tel-input:focus-within {
  @apply shadow-none;
}

.work-with-us .vti__dropdown:focus,
.work-with-us .vti__input:focus {
  @apply ring-primary-500 border-primary-500 border-2;
}

.work-with-us .vti__input {
  @apply border-[#f1d4b5] text-sm bg-[#FFFFFF] bg-opacity-10 text-white;
}

.work-with-us .vue-tel-input .vs__search::placeholder,
.work-with-us .vue-select-chooser .vs__search::placeholder {
  @apply text-white !text-opacity-70;
}
.work-with-us .vue-select-chooser.is-invalid .vs__dropdown-toggle {
  @apply border-red-500;
}
.work-with-us .vti__dropdown {
  @apply rounded-e-none rounded-md border-[#f1d4b5] border-r border-opacity-30 !bg-white !bg-opacity-10 outline-none;
}

.work-with-us #phone.vue-tel-input.is-invalid {
  @apply bg-transparent !border-red-500;
}

.work-with-us .vti__dropdown-list {
  @apply text-black bg-white;
}

.work-with-us .vue-select-chooser .vs__search::placeholder,
.work-with-us .vue-select-chooser .vs__dropdown-toggle,
.work-with-us .vue-select-chooser .vs__dropdown-menu {
  @apply mt-2 w-full rounded-[10px] text-sm  border border-[#DBDBDB] border-opacity-30 font-myriad-pro font-normal;
}

.work-with-us .vue-select-chooser .vs__dropdown-toggle {
  @apply bg-opacity-10 bg-white text-white h-[48px] !mt-0;
}

.work-with-us
  .vue-select-chooser
  .vs__dropdown-toggle
  .vs__selected-options
  .vs__selected {
  @apply text-white;
}

.work-with-us .vue-select-chooser .vs__dropdown-toggle:focus {
  @apply focus:ring-primary-500 focus:border-primary-500 border-2;
}
::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.work-with-us input::placeholder,
.work-with-us textarea::placeholder {
  @apply !text-white !text-opacity-70;
}
::-webkit-calendar-picker-indicator {
  filter: invert(1);
  transform: scale(1.3);
}
.has-value::-webkit-datetime-edit-text,
.has-value::-webkit-datetime-edit-month-field,
.has-value::-webkit-datetime-edit-day-field,
.has-value::-webkit-datetime-edit-year-field {
  @apply !text-white;
}

::-webkit-datetime-edit-text,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-year-field,
.work-with-us input::placeholder,
.work-with-us textarea::placeholder {
  @apply !text-white !text-opacity-70;
}
</style>
