<template>
  <div class="rounded" :class="!baidu ? 'w-[262px]' : 'w-auto'">
    <div class="flex justify-between pt-3.5" :class="!baidu ? 'px-4' : ''">
      <h4
        class="text-base font-myriad-pro font-semibold text-black leading-[22px] pr-3"
      >
        {{ props.info.name }} <br />
        <div class="flex gap-[5px] items-baseline">
          <a
            :href="urlToMap"
            target="_blank"
            class="pt-0.5 text-center no-underline mr-2 text-black font-myriad-pro block font-medium text-[13px] leading-[24px]"
          >
            {{ $t("common.open") }}
          </a>
          <span class="w-1 h-1 rounded-full bg-[#E4E5E6]"></span>
          <div>
            <span
              class="tracking-[-0.11px] text-[10px] font-myriad-pro font-semibold italic leading-normal uppercase bg-[#F5F7F8] pl-[10px] pt-[4px] pb-[4px] pr-[10px] rounded-[14px]"
            >
              {{ $t("common.store") }}
            </span>
          </div>
        </div>
      </h4>
    </div>

    <hr class="mt-2.5 mb-4" />

    <div
      v-if="props.showDetail"
      class=""
      :class="!baidu ? 'px-4 pb-[15px]' : ''"
    >
      <ul>
        <li v-if="props.info.phone" class="mb-[18px]">
          <strong
            class="font-semibold text-[12px] font-myriad-pro leading-[12.1px] text-[#727272]"
            >{{ $t("common.user_phone_number") }}</strong
          >
          <br />
          <span
            class="inline-block mt-[3px] text-sm font-light text-black font-myriad-pro"
          >
            {{ props.info.phone }}
            <IconsCopyclipboard
              class="inline-block w-4 h-4 cursor-pointer"
              icon-id="clip0_1071_18978"
              @click="copyClipboardText(props.info.phone)"
            />
          </span>
        </li>
        <li v-if="props.info.email" class="mb-[18px]">
          <strong
            class="font-semibold text-[12px] font-myriad-pro leading-[12.1px] text-[#727272]"
          >
            {{ $t("common.email") }}
          </strong>
          <br />
          <span
            class="flex mt-[3px] items-center text-sm font-medium text-black font-myriad-pro"
          >
            {{ props.info.email }}
            <span class="inline-block ml-1 mt-0.5 w-4 h-4">
              <IconsCopyclipboard
                class="w-4 h-4 cursor-pointer"
                icon-id="clip0_1071_18956"
                @click="copyClipboardText(props.info.address)"
              />
            </span>
          </span>
        </li>
        <li class="mb-[18px]">
          <strong
            class="font-semibold text-[12px] font-myriad-pro leading-[12.1px] text-[#727272]"
          >
            {{ $t("common.address") }}
          </strong>
          <br />
          <p
            class="relative mt-[3px] block pr-3 text-sm font-medium text-black font-myriad-pro"
          >
            {{ props.info.address }}
            <span class="absolute inline-block ml-1 mt-0.5 w-4 h-4">
              <IconsCopyclipboard
                icon-id="clip0_1071_18956"
                @click="copyClipboardText(props.info.address)"
                class="w-4 h-4 cursor-pointer"
              />
            </span>
          </p>
        </li>
      </ul>
      <div class="mt-5">
        <a
          :href="urlToMap"
          target="_blank"
          class="block py-[11px] px-[90px] text-center text-white whitespace-nowrap text-xs font-medium shadow-sm bg-[#2C3032] hover:bg-gray-700 hover:text-white rounded-[10px] w-full focus:no-underline"
          v-text="$t('common.open_map')"
        />
        <a
          v-if="mainStore.enableShop"
          :href="$i18nPath('/search?location=')"
          class="flex py-[11px] my-2 no-underline justify-center whitespace-nowrap text-white text-xs font-medium shadow-sm bg-[#2C3032] hover:bg-gray-700 hover:text-white rounded-md w-full focus:no-underline"
          v-text="$t('common.see_inventories')"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { useTranslation } from "i18next-vue";
import { useMainStore } from "@/store/index";

const props = defineProps({
  baidu: {
    type: Boolean,
    default: false,
  },
  info: {
    type: Object,
    required: true,
  },
  position: {
    type: Object,
    required: true,
  },
  showDetail: {
    type: Boolean,
    required: true,
  },
  distance: {
    type: Number,
    required: false,
    default: null,
  },
  activeTab: {
    type: String,
    default: null,
  },
});

const nuxtApp = useNuxtApp();
const { t } = useTranslation();
const mainStore = useMainStore();

const urlToMap = computed(() => {
  return props.baidu
    ? `http://api.map.baidu.com/marker?location=${props.position.lat},${props.position.lng}&output=html&s=gibberish`
    : `https://www.google.com/maps/dir//${props.position.lat},${props.position.lng}`;
});

async function copyClipboardText(text) {
  try {
    if (navigator.clipboard) {
      await navigator.clipboard.writeText(text);
    }

    nuxtApp.$toast.success(t("common.copied_clipboard"));
  } catch (e) {
    console.error(e);
  }
}
</script>

<style>
.gm-style-iw-tc::before {
  content: "";
  box-shadow: 0 -25px 0 0 white;
  clip-path: polygon(0 0, 0 100%, 100% 0) !important;
  @apply absolute !bottom-[-24px] rounded-tl-[25px]  w-[18px] h-[36px] left-[47%] bg-transparent;
}
.gm-style-iw-tc::after {
  content: "";
  box-shadow: 0 -25px 0 0 white;
  clip-path: polygon(0 0, 100% 100%, 100% 0) !important;
  @apply absolute !bottom-[-24px] !top-[auto] !left-auto !rounded-tr-[18px] !w-[18px] !h-[36px] !right-[50%] !bg-transparent;
}
.gm-style .gm-style-iw-tc {
  @apply !top-[-1px];
}
.gm-style .gm-style-iw-d {
  max-height: max-content !important;
  @apply overflow-auto !important;
  /* p-2.5 pt-3.5 pb-[15px] */
}
</style>
