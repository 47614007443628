<template>
  <div
    class="h-full"
    :class="[
      props.showDetail
        ? 'border-secondary-dark border'
        : 'border-[#E2E2E2] border bg-white',
      'py-3 pr-3 pl-5 relative rounded-xl',
    ]"
  >
    <div class="py-1 space-x-2">
      <span
        v-if="pointOfSale"
        :class="[
          props.showDetail
            ? 'bg-secondary-dark text-white'
            : 'bg-[#F5F7F8] text-black',
          'uppercase rounded-[21px] px-[10px] text-[11px] py-[3px] italic font-semibold font-myriad-pro',
        ]"
      >
        {{ pointOfSale }}
      </span>
      <span
        v-if="serviceCenter"
        :class="[
          props.showDetail
            ? 'bg-secondary-dark text-white'
            : 'bg-[#F5F7F8] text-black',
          'uppercase rounded-[21px] px-[10px] py-[3px] text-[11px] italic font-semibold font-myriad-pro',
        ]"
      >
        {{ serviceCenter }}
      </span>
    </div>
    <div class="absolute top-4 ltr:right-3 rtl:left-4">
      <button
        :class="[
          props.showDetail
            ? 'border-[#cccccc] border'
            : 'border border-[#E2E2E2]',
          'p-3 rounded-[10px]',
        ]"
        @click="showOnMap"
      >
        <OpenMap :color="props.showDetail ? '#13B973' : 'black'" />
      </button>
    </div>
    <div
      v-if="props.info.name"
      class="py-[10px] pr-12 w-full text-black md:normal-case text-lg font-myriad-pro"
    >
      {{ props.info.name }}
    </div>
    <div
      v-if="props.info.phone"
      class="flex items-center text-sm font-normal text-black font-myriad-pro"
    >
      {{ props.info.phone }}
      <span @click="copyClipboard(props.info.phone)">
        <CopyClipboard
          v-if="props.info.phone"
          class="w-4 h-4 ml-2 cursor-pointer"
        />
      </span>
    </div>
    <hr
      v-if="props.info.email"
      :class="[
        { '': props.showDetail },
        ' my-[14px] border-[#E2E2E2] text-[#E2E2E2]',
      ]"
    />
    <div
      class="flex items-center text-sm font-normal text-black font-myriad-pro"
    >
      {{ props.info.email }}
      <CopyClipboard
        v-if="props.info.email"
        class="w-4 h-4 ml-2 cursor-pointer"
        @click="copyClipboard(props.info.email)"
      />
    </div>
    <hr
      v-if="props.info.address"
      :class="[{ '': props.showDetail }, ' my-[14px]']"
    />
    <div
      v-if="props.info.address"
      class="inline-block text-sm font-normal text-black"
    >
      <p class="relative block pr-3 font-normal font-myriad-pro">
        {{ props.info.address }}
        <span class="absolute inline ml-1 mt-0.5 font-normal">
          <CopyClipboard
            class="cursor-pointer"
            @click="copyClipboard(props.info.address)"
          />
        </span>
      </p>
    </div>
  </div>
</template>
<script setup>
import CopyClipboard from "~/assets/svg/heroicons/copy-clipboard.svg?component";
import { useTranslation } from "i18next-vue";

const props = defineProps({
  baidu: {
    type: Boolean,
    default: false,
  },
  info: {
    type: Object,
    required: true,
  },
  position: {
    type: Object,
    required: true,
  },
  showDetail: {
    type: Boolean,
    required: true,
  },
  distance: {
    type: Number,
    required: false,
    default: null,
  },
});

const nuxtApp = useNuxtApp();
const { t } = useTranslation();

const isDistributor = ref(true);

const urlToMap = computed(() => {
  return props.baidu
    ? `http://api.map.baidu.com/marker?location=${props.position.lat},${props.position.lng}&output=html&s=gibberish`
    : `https://www.google.com/maps/dir//${props.position.lat},${props.position.lng}`;
});
const pointOfSale = computed(() => {
  let keyName;
  if (props.info.distributor_categories.point_of_sale) {
    keyName = "Store";
  }
  return keyName;
});

const serviceCenter = computed(() => {
  let keyName;
  if (props.info.distributor_categories.service_center) {
    keyName = "Service";
  }
  return keyName;
});

async function copyClipboard(text) {
  try {
    if (!process.browser) return false;

    if (navigator.clipboard) {
      await navigator.clipboard.writeText(text);
    }

    nuxtApp.$toast.success(t("common.copied_clipboard"));
  } catch (e) {
    console.error(e);
  }
}

function showOnMap() {
  window.open(urlToMap.value, "_blank");
}
function distributorCategories() {
  if (props.info.distributor_categories.point_of_sale) {
    return "Store";
  } else if (props.info.distributor_categories.service_center) {
    return "Service";
  } else {
    isDistributor.value = false;
  }
  isDistributor.value = true;
}
</script>
<style scoped>
.address-image::after {
  margin-right: 8px;
  cursor: pointer;
}
</style>
