<template>
  <footer
    class="bg-center bg-no-repeat bg-cover bg-secondary"
    id="footer"
    aria-labelledby="footerHeading"
  >
    <div class="container md:pt-[51px] py-[30px] lg:pb-[30px]">
      <section
        class="flex flex-col items-center justify-between xl:gap-8 lg:flex-row"
      >
        <!-- Logo -->
        <div class="flex flex-col items-center py-2 lg:flex-row xl:pr-11">
          <nuxt-link
            aria-label="Benda Keeway Logo"
            :to="$i18nPath('/')"
            :prefetch="false"
          >
            <NuxtImg
              src="/benelli-footer-logo.png"
              alt="benelli logo mark"
              class="h-[77px] w-[75px] lg:h-[72px] lg:w-[70px] xl:h-[85px] xl:w-[83px]"
            />
          </nuxt-link>
          <div
            class="xl:ml-[20px] rtl:lg:mr-6 ltr:lg:ml-6 md:ml-[30px] mt-4 lg:mt-0"
          >
            <Slogan
              class="h-[11px] w-[335px] lg:h-[8px] lg:w-[263px] xl:w-[305px] xl:h-[10px]"
            />
          </div>
        </div>

        <!-- Social networks -->
        <div
          v-if="
            checkSocialExist &&
            currentSiteSettings &&
            currentSiteSettings.socials
          "
          class="flex flex-col items-center justify-between w-full mt-[35px] lg:items-start xl:items-center lg:mt-0 xl:gap-2 xl:flex-row lg:w-auto lg:justify-end"
        >
          <p
            class="text-xs italic font-normal lg:ml-2 font-myriad-pro text-secondary-light"
          >
            {{ $t("common.find_us") }}
          </p>
          <div
            class="flex items-center justify-center mt-3 -space-x-2 lg:mt-[10px] xl:mt-0 md:justify-end"
          >
            <a
              v-if="currentSiteSettings.socials.facebook"
              :href="currentSiteSettings.socials.facebook"
              target="_blank"
              aria-label="Facebook link"
              class="flex gap-3 text-white hover:opacity-90"
            >
              <IconsFacebook
                class="w-16 h-16 lg:w-[53px] lg:h-[53px] 1.5xl:w-[69.82px] 1.5xl:h-[69.82px]"
              />
            </a>
            <a
              v-if="currentSiteSettings.socials.instagram"
              :href="currentSiteSettings.socials.instagram"
              target="_blank"
              aria-label="Instagram link"
              class="flex gap-3 text-white hover:opacity-90"
            >
              <IconsInstagram
                class="w-16 h-16 lg:w-[53px] lg:h-[53px] 1.5xl:w-[69.82px] 1.5xl:h-[69.82px]"
              />
            </a>
            <a
              v-if="currentSiteSettings.socials.linkedin"
              :href="currentSiteSettings.socials.linkedin"
              target="_blank"
              aria-label="LinkedIn link"
              class="flex gap-3 text-white hover:opacity-90"
            >
              <IconsLinkedIn
                class="w-16 h-16 lg:w-[53px] lg:h-[53px] 1.5xl:w-[69.82px] 1.5xl:h-[69.82px]"
              />
            </a>
            <a
              v-if="currentSiteSettings.socials.twitter"
              :href="currentSiteSettings.socials.twitter"
              target="_blank"
              aria-label="Twitter link"
              class="flex gap-3 text-white hover:opacity-90"
            >
              <IconsTwitter
                class="w-16 h-16 lg:w-[53px] lg:h-[53px] 1.5xl:w-[69.82px] 1.5xl:h-[69.82px]"
              />
            </a>
            <a
              v-if="currentSiteSettings.socials.youtube"
              :href="currentSiteSettings.socials.youtube"
              target="_blank"
              aria-label="YouTube link"
              class="flex gap-3 text-white hover:opacity-90"
            >
              <IconsYouTube
                class="w-16 h-16 lg:w-[53px] lg:h-[53px] 1.5xl:w-[69.82px] 1.5xl:h-[69.82px]"
              />
            </a>
            <a
              v-if="currentSiteSettings.socials.tiktok"
              :href="currentSiteSettings.socials.tiktok"
              target="_blank"
              aria-label="TikTok link"
              class="flex gap-3 text-white hover:opacity-90"
            >
              <IconsTikTok
                class="w-16 h-16 lg:w-[53px] lg:h-[53px] 1.5xl:w-[69.82px] 1.5xl:h-[69.82px]"
              />
            </a>
          </div>
        </div>
      </section>

      <div
        v-if="hasMenuDisplay"
        class="overflow-hidden lg:px-0 pb-0 lg:grid lg:grid-cols-4 lg:gap-10 mt-[30px] lg:mt-[66px]"
      >
        <!-- Brand -->
        <Collapsible
          :aria-label="
            $t('common.brand_in_country', {
              brand_name: $config.APP_NAME,
              country_name: countryName,
              interpolation: { prefix: '[:', suffix: ']' },
            })
          "
          :is-disabled="checkBrowserWidth"
          :is-expanded="checkBrowserWidth"
          active-class="border-white"
          inactive-class="border-secondary-200"
          class="py-3 border-b border-solid lg:border-none lg:py-0"
        >
          <template #trigger="{ isActive }">
            <div class="flex justify-between cursor-default customTrigger">
              <h3
                class="inline uppercase leading-[22px] font-myriad-pro font-normal text-primary lg:text-xs xl:text-base text-base my-0"
              >
                {{
                  $t("common.brand_in_country", {
                    brand_name: $config.APP_NAME,
                    country_name: countryName,
                    interpolation: { prefix: "[:", suffix: "]" },
                  })
                }}
              </h3>
              <AngleDown
                class="inline w-5 h-5 ml-1 transition-transform lg:hidden"
                :class="isActive ? 'rotate-180 text-primary' : ' text-white'"
              />
            </div>
          </template>

          <ul class="pt-[10px] mt-0">
            <li v-if="currentSite && currentSite.news">
              <nuxt-link
                :to="$i18nPath(store.pageLinks['news'])"
                class="text-xs lg:text-[10px] uppercase leading-5 md:leading-[15px] xl:leading-[normal] py-[7px] md:py-2 xl:py-[5px] inline-block font-myriad-pro text-secondary-light hover:no-underline hover:opacity-90 hover:text-primary xl:text-xs"
                :prefetch="false"
              >
                {{ $t("common.news") }}
              </nuxt-link>
            </li>
            <li v-if="store.country === 'it'">
              <a
                href="http://presskit.benelli.com/"
                target="_blank"
                v-text="$t('common.press_release')"
                class="text-xs lg:text-[10px] uppercase leading-5 md:leading-[15px] xl:leading-[normal] py-[7px] md:py-2 xl:py-[5px] inline-block font-myriad-pro text-secondary-light hover:no-underline hover:opacity-90 hover:text-primary xl:text-xs"
              >
              </a>
            </li>
            <li>
              <nuxt-link
                :to="$i18nPath(store.pageLinks['stores'])"
                class="text-xs lg:text-[10px] uppercase leading-5 md:leading-[15px] xl:leading-[normal] py-[7px] md:py-2 xl:py-[5px] inline-block font-myriad-pro text-secondary-light hover:no-underline hover:opacity-90 hover:text-primary xl:text-xs"
                :prefetch="false"
              >
                {{ $t("common.find_a_store") }}
              </nuxt-link>
            </li>
            <li
              v-if="currentSiteSettings && currentSiteSettings.become_a_partner"
            >
              <nuxt-link
                v-if="store.country === 'int'"
                :to="$i18nPath(store.pageLinks['become-a-distributor'])"
                class="text-xs lg:text-[10px] uppercase leading-5 md:leading-[15px] xl:leading-[normal] py-[7px] md:py-2 xl:py-[5px] inline-block font-myriad-pro text-secondary-light hover:no-underline hover:opacity-90 hover:text-primary xl:text-xs"
                :prefetch="false"
              >
                {{ $t("common.become_a_distributor") }}
              </nuxt-link>

              <nuxt-link
                v-else
                :to="$i18nPath(store.pageLinks['become-a-dealer'])"
                class="text-xs lg:text-[10px] uppercase leading-5 md:leading-[15px] xl:leading-[normal] py-[7px] md:py-2 xl:py-[5px] inline-block font-myriad-pro text-secondary-light hover:no-underline hover:opacity-90 hover:text-primary xl:text-xs"
                :prefetch="false"
              >
                {{ $t("common.become_a_dealer") }}
              </nuxt-link>
            </li>
          </ul>
        </Collapsible>

        <!-- Company -->
        <Collapsible
          :aria-label="$t('common.company')"
          :is-disabled="checkBrowserWidth"
          :is-expanded="checkBrowserWidth"
          active-class="border-white"
          inactive-class="border-secondary-200"
          class="pt-6 pb-[11px] border-b border-solid lg:border-none lg:py-0"
        >
          <template #trigger="{ isActive }">
            <div class="flex justify-between cursor-default customTrigger">
              <h3
                class="inline uppercase leading-[22px] font-myriad-pro font-normal text-primary lg:text-xs xl:text-base text-base my-0"
                v-text="$t('common.company')"
              />
              <AngleDown
                class="inline w-5 h-5 ml-1 text-primary lg:hidden"
                :class="isActive ? 'rotate-180 text-primary' : ' text-white'"
              />
            </div>
          </template>

          <ul class="pt-[10px] my-0">
            <li>
              <nuxt-link
                :to="$i18nPath(store.pageLinks['about-us'])"
                class="text-xs lg:text-[10px] uppercase font-myriad-pro text-secondary-light py-[7px] md:py-2 xl:py-[5px] inline-block leading-5 md:leading-[15px] xl:leading-[normal] hover:no-underline hover:opacity-90 hover:text-primary xl:text-xs"
                :prefetch="false"
              >
                {{ $t("common.about_us") }}
              </nuxt-link>
            </li>

            <li v-if="currentSiteSettings && currentSiteSettings.work_with_us">
              <nuxt-link
                :to="$i18nPath(store.pageLinks['work-with-us'])"
                class="text-xs lg:text-[10px] uppercase font-myriad-pro text-secondary-light py-[7px] md:py-2 xl:py-[5px] inline-block leading-5 md:leading-[15px] xl:leading-[normal] hover:no-underline hover:opacity-90 hover:text-primary xl:text-xs"
                :prefetch="false"
              >
                {{
                  $t("common.work_with_brand", {
                    brand: "Benelli ",
                    interpolation: { prefix: "[:", suffix: "]" },
                  })
                }}
              </nuxt-link>
            </li>
            <li>
              <nuxt-link
                :to="$i18nPath(store.pageLinks['the-museum'])"
                class="text-xs lg:text-[10px] uppercase font-myriad-pro text-secondary-light py-[7px] md:py-2 xl:py-[5px] inline-block leading-5 md:leading-[15px] xl:leading-[normal] hover:no-underline hover:opacity-90 hover:text-primary xl:text-xs"
                :prefetch="false"
              >
                {{ $t("common.museum") }}
              </nuxt-link>
            </li>
            <!-- <li
              v-if="
                currentSiteSettings && currentSiteSettings.test_ride_inquiry
              "
            >
              <nuxt-link
                :to="$i18nPath('/test-drive')"
                class="text-xs uppercase font-myriad-pro text-white leading-[18px] py-[5px] hover:no-underline hover:opacity-90 hover:text-primary"
              >
                {{ $t("common.test_ride_inquiry.title") }}
              </nuxt-link>
            </li> -->
          </ul>
        </Collapsible>

        <!-- Customer service -->
        <Collapsible
          :aria-label="$t('common.customer_services')"
          :is-disabled="checkBrowserWidth"
          :is-expanded="checkBrowserWidth"
          active-class="border-white"
          inactive-class="border-secondary-200"
          class="pt-6 pb-3 border-b border-solid lg:border-none lg:py-0"
        >
          <template #trigger="{ isActive }">
            <div class="flex justify-between cursor-default customTrigger">
              <h3
                class="inline uppercase leading-[22px] font-myriad-pro font-normal text-primary lg:text-xs xl:text-base text-base my-0"
                v-text="$t('common.customer_services')"
              />
              <AngleDown
                class="inline w-5 h-5 ml-1 text-primary lg:hidden"
                :class="isActive ? 'rotate-180 text-primary' : ' text-white'"
              />
            </div>
          </template>

          <ul class="pt-[10px] my-0">
            <li
              v-if="
                currentSiteSettings && currentSiteSettings.intercom.integrate
              "
            >
              <a
                :aria-label="$t('common.chat_now')"
                href=""
                class="text-xs lg:text-[10px] uppercase font-myriad-pro text-secondary-light py-[7px] md:py-2 xl:py-[5px] inline-block leading-5 md:leading-[15px] xl:leading-[normal] hover:no-underline hover:opacity-90 hover:text-primary xl:text-xs"
                @click.prevent="intercom.show()"
              >
                {{ $t("common.chat_now") }}
              </a>
            </li>
            <li>
              <nuxt-link
                :aria-label="$t('common.contact_us')"
                :to="$i18nPath(store.pageLinks['contact-us'])"
                class="text-xs lg:text-[10px] uppercase font-myriad-pro text-secondary-light py-[7px] md:py-2 xl:py-[5px] inline-block leading-5 md:leading-[15px] xl:leading-[normal] hover:no-underline hover:opacity-90 hover:text-primary xl:text-xs"
                :prefetch="false"
              >
                {{ $t("common.contact_us") }}
              </nuxt-link>
            </li>
            <li v-if="store.country === 'it'">
              <a
                href="https://service.benelli.com/"
                target="_blank"
                class="text-xs lg:text-[10px] uppercase font-myriad-pro text-secondary-light py-[7px] md:py-2 xl:py-[5px] inline-block leading-5 md:leading-[15px] xl:leading-[normal] hover:no-underline hover:opacity-90 hover:text-primary xl:text-xs"
              >
                RMI
              </a>
            </li>
            <li v-if="store.country === 'it'">
              <a
                href="https://myservicegate.com/"
                target="_blank"
                class="text-xs lg:text-[10px] uppercase font-myriad-pro text-secondary-light py-[7px] md:py-2 xl:py-[5px] inline-block leading-5 md:leading-[15px] xl:leading-[normal] hover:no-underline hover:opacity-90 hover:text-primary xl:text-xs"
              >
                Gestione Ordine E Garanzie
              </a>
            </li>
            <li v-if="store.country === 'ar'">
              <a
                href="https://www.benelli-servicios.com.ar/Benelli/login.zul"
                target="_blank"
                rel="noopener"
                class="text-xs lg:text-[10px] uppercase font-myriad-pro text-secondary-light py-[7px] md:py-2 xl:py-[5px] inline-block leading-5 md:leading-[15px] xl:leading-[normal] hover:no-underline hover:opacity-90 hover:text-primary xl:text-xs"
              >
                EService Benelli Argentina
              </a>
            </li>
            <li v-if="currentSite.manuals">
              <nuxt-link
                :to="$i18nPath(store.pageLinks['manuals'])"
                class="text-xs lg:text-[10px] uppercase font-myriad-pro text-secondary-light py-[7px] md:py-2 xl:py-[5px] inline-block leading-5 md:leading-[15px] xl:leading-[normal] hover:no-underline hover:opacity-90 hover:text-primary xl:text-xs"
                :prefetch="false"
              >
                {{ $t("common.manuals") }}
              </nuxt-link>
            </li>
            <li v-if="currentSite.country.continent === 'Europe'">
              <a
                href="https://myservicegate.com/richiami_aggiornamenti/"
                class="text-xs lg:text-[10px] uppercase font-myriad-pro text-secondary-light py-[7px] md:py-2 xl:py-[5px] inline-block leading-5 md:leading-[15px] xl:leading-[normal] hover:no-underline hover:opacity-90 hover:text-primary xl:text-xs"
                target="_"
              >
                {{ $t("common.recall_updates") }}
              </a>
            </li>
            <li v-if="store.country === 'es'">
              <a
                href="https://spaziobenelli.blog/"
                class="text-xs lg:text-[10px] uppercase font-myriad-pro text-secondary-light py-[7px] md:py-2 xl:py-[5px] inline-block leading-5 md:leading-[15px] xl:leading-[normal] hover:no-underline hover:opacity-90 hover:text-primary xl:text-xs"
              >
                {{ $t("common.blog") }}
              </a>
            </li>
          </ul>
        </Collapsible>

        <!-- Group companies -->
        <Collapsible
          :aria-label="$t('common.legals')"
          :is-disabled="checkBrowserWidth"
          :is-expanded="checkBrowserWidth"
          active-class="border-white"
          inactive-class="border-secondary-200"
          class="pt-6 pb-3 border-b border-solid lg:border-none lg:py-0"
        >
          <template #trigger="{ isActive }">
            <div class="flex justify-between cursor-default customTrigger">
              <h3
                class="inline uppercase leading-[22px] font-myriad-pro font-normal text-primary lg:text-xs xl:text-base text-base my-0"
                v-text="$t('common.legals')"
              />
              <AngleDown
                class="inline h-5 ml-1 text-white lg:hidden"
                :class="isActive ? 'rotate-180 text-primary' : ' text-white'"
              />
            </div>
          </template>
          <ul class="my-0 mt-[7px] md:mt-[5px]">
            <li>
              <a
                href="https://cdn.bbike-cdn.com.cn/benelli.com/ppq.pdf"
                target="_blank"
                class="text-xs lg:text-[10px] uppercase font-myriad-pro text-secondary-light py-[7px] md:py-2 xl:py-[5px] inline-block leading-5 md:leading-[15px] xl:leading-[normal] hover:no-underline hover:opacity-90 hover:text-primary xl:text-xs"
                v-text="$t('common.quality_policy')"
              />
            </li>
            <li>
              <nuxt-link
                v-if="store.country === 'ar'"
                :to="$i18nPath(store.pageLinks['warranty'])"
                class="text-xs lg:text-[10px] uppercase font-myriad-pro text-secondary-light py-[7px] md:py-2 xl:py-[5px] inline-block leading-5 md:leading-[15px] xl:leading-[normal] hover:no-underline hover:opacity-90 hover:text-primary xl:text-xs"
                :prefetch="false"
              >
                {{ $t("common.warranty_notice") }}
              </nuxt-link>
            </li>
            <li>
              <a
                href="https://cdn.bbike-cdn.com.cn/benelli.com/iso-2023.pdf"
                target="_blank"
                class="text-xs lg:text-[10px] uppercase font-myriad-pro text-secondary-light py-[7px] md:py-2 xl:py-[5px] inline-block leading-5 md:leading-[15px] xl:leading-[normal] hover:no-underline hover:opacity-90 hover:text-primary xl:text-xs"
              >
                UNI EN ISO 9001:2015 {{ $t("common.certificate") }}
              </a>
            </li>
            <li v-if="store.country === 'it'">
              <a
                href="https://benellisrl.dynaleaks.it/"
                target="_blank"
                class="text-xs lg:text-[10px] uppercase font-myriad-pro text-secondary-light py-[7px] md:py-2 xl:py-[5px] inline-block leading-5 md:leading-[15px] xl:leading-[normal] hover:no-underline hover:opacity-90 hover:text-primary xl:text-xs"
              >
                {{ $t("common.whistleblowing") }}
              </a>
            </li>
            <li v-if="currentSite.country.continent === 'Europe'">
              <div
                class="leading-4 text-xs lg:text-[10px] xl:text-xs text-secondary-light py-[7px] md:py-2 xl:py-[5px]"
              >
                <span class="italic font-normal uppercase font-myriad-pro">
                  Per questioni inerenti il GDPR si prega di inviare una mail a: </span
                ><br />
                <a
                  href="mailto:dpo@benelli.com"
                  target="_blank"
                  class="text-xs underline lowercase lg:text-[10px] font-myriad-pro hover:no-underline hover:opacity-90 hover:text-primary xl:text-xs"
                >
                  dpo@benelli.com
                </a>
              </div>
            </li>
            <li v-if="store.country === 'us'">
              <div>
                <a
                  href="tel:+800-832-2966"
                  target="_blank"
                  class="flex justify-center mb-0 text-base lg:text-[10px] xl:text-xs italic font-bold text-white lg:block font-myriad-pro"
                >
                  800-832-2966
                </a>
              </div>
            </li>
          </ul>
        </Collapsible>
      </div>

      <section
        class="flex flex-col items-center lg:items-end justify-end lg:gap-8 lg:flex-row mt-10 xl:mt-[74px]"
      >
        <div class="flex-1">
          <div v-if="store.country === 'cn'">
            <img
              class="h-[120px] lg:h-[84px]"
              src="@/assets/images/qrcode/wechat.png"
              alt="WeChat QRCode"
            />
          </div>
        </div>

        <div class="flex items-center justify-center flex-1 mt-6 lg:mt-0">
          <a
            aria-label="Centrostilebenelli"
            target="_blank"
            href="https://www.instagram.com/centrostilebenelli/"
          >
            <CentroStileBenelli
              class="xl:w-[212.11px] xl:h-[28px] lg:w-[175px] lg:h-[23px] w-[227.26px] h-[30px] hover:text-primary text-[#BCBCBC]"
            />
          </a>
        </div>

        <div class="flex-1"></div>
      </section>
      <!-- Footer below border -->
      <div
        :class="[
          store.locale === 'el' ||
          store.locale === 'es' ||
          store.locale === 'pl'
            ? 'gap-8 xl:gap-[20px]'
            : 'gap-8 xl:gap-[46px] ',
        ]"
        class="relative flex flex-col w-full lg:flex-row items-center justify-center md:justify-between pt-6 pb-7.5 mt-[16px] lg:mt-4 border-t border-primary-600 lg:px-0 md:gap-4 md:pb-0"
      >
        <div
          :class="[
            store.locale === 'el'
              ? 'hidden h-12 xl:block w-[80px]'
              : 'hidden h-12 xl:block w-[170px]',
          ]"
        ></div>
        <div
          class="flex flex-col items-center w-full gap-4 text-sm lg:items-start xl:items-center md:w-auto md:mx-0 xl:mx-auto lg:bottom-0 font-myriad-pro sm:mx-4 md:relative md:bottom-auto"
        >
          <!-- Copyright -->
          <p
            class="flex font-normal text-center xl:justify-center text-dark-gray-100 md:text-left"
          >
            Benelli &copy; {{ $t("common.copyright") }} - {{ year }} -
            {{ $t("common.all_rights_reserved") }}
          </p>

          <!-- Legal -->
          <div
            :class="[
              store.locale === 'el'
                ? 'md:gap-3'
                : 'md:gap-x-3 md:gap-3 lg:gap-[21px] xl:gap-[24px]',
            ]"
            class="grid flex-row items-center justify-between w-full grid-cols-2 gap-y-6 md:w-auto md:flex"
          >
            <div
              class="w-full md:w-auto"
              v-for="(legal, k) in legals"
              :k="k"
              :class="
                k % 2 !== 0 ? 'rtl:text-left ltr:text-right md:text-center' : ''
              "
            >
              <nuxt-link
                :to="$i18nPath(`/${legal.link}`)"
                class="font-normal text-dark-gray-100 hover:no-underline hover:text-primary hover:text-opacity-70"
                :prefetch="false"
              >
                {{ $t(`common.${legal.name}`) }}
              </nuxt-link>
            </div>
          </div>
        </div>

        <!-- Locales -->
        <div>
          <LocaleSelector
            class="flex justify-center lg:block"
            name="footer"
            position="bottom"
          />
        </div>
      </div>
    </div>
    <MemeLink />
    <WhatsappChat
      v-if="currentSiteSettings && currentSiteSettings.whatsapp"
      :phone="currentSiteSettings.whatsapp"
    />
  </footer>
</template>
<script setup>
import { useMainStore } from "@/store/index";
import CloseIcon from "@/assets/svg/heroicons/x.svg?component";
import AngleDown from "@/assets/svg/heroicons/angle-down.svg?component";
import CentroStileBenelli from "~/assets/svg/centro-stile-benelli.svg?component";
import Slogan from "~/assets/svg/slogan.svg?component";
import FooterLogo from "~/assets/svg/footer-logo.svg?component";
import { useLocaleStore } from "@/store/locales";

const store = useMainStore();
const nuxtApp = useNuxtApp();
const localeStore = useLocaleStore();

const $i18nPath = nuxtApp.$i18nPath;
const $config = nuxtApp.$config.public;
const browserWidth = ref(0);
const hasMenuDisplay = ref(false);
const state = reactive({ group_companies: [] });
const year = ref(new Date().getFullYear());
const intercom = ref(
  typeof nuxtApp.$intercom !== "undefined" ? nuxtApp.$intercom : null
);

const products = store.products;
const sites = store.sites;
const country = store.country;
const locales = localeStore.defaultLocales;
const currentSiteSettings = store.currentSiteSettings;
const currentSite = store.currentSite;
const features = store.features;

const textClass = computed(() => {
  return store.locale === "el"
    ? "text-[17px] 2xl:text-[20px] font-bold"
    : "text-[30px]";
});

const legals = computed(() => {
  let disclaimer = [];
  let terms = [];
  if (
    currentSite &&
    currentSite.disclaimer &&
    currentSite.country.continent === "Europe"
  ) {
    disclaimer = [
      {
        link: "disclaimer",
        name: "disclaimer",
      },
    ];
  }
  if (store && store.country !== "it") {
    terms = [
      {
        link: "terms",
        name: "terms_and_conditions",
      },
    ];
  }
  return [
    ...disclaimer,
    ...terms,
    {
      link: "privacy-policy",
      name: "privacy_policy",
    },
    {
      link: "cookie-policy",
      name: "cookie_policy",
    },
    {
      link: "unsubscribe",
      name: "unsubscribe",
    },
  ];
});

const countryName = computed(() => {
  const countryData = locales.length
    ? locales.find((x) => x.slug === country)
    : null;
  return countryData ? countryData.country.name : "";
});

const checkSocialExist = computed(() => {
  let data = [
    "facebook",
    "instagram",
    "linkedin",
    "twitter",
    "youtube",
    "tiktok",
  ];
  if (!currentSiteSettings || !currentSiteSettings.socials) {
    return null;
  }
  return data.find((_v) => currentSiteSettings.socials[_v]);
});

const checkBrowserWidth = computed(() => {
  return browserWidth.value >= 1024 ? true : false;
});

onMounted(() => {
  const findData = features.find((_v) => _v.slug == "group-companies");

  if (findData && findData.settings) {
    state.group_companies = findData.settings;
  }
  browserWidth.value = window.innerWidth;
  hasMenuDisplay.value = true;
  window.addEventListener("resize", windowResized);
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", windowResized);
});

function windowResized() {
  browserWidth.value = window.innerWidth;
}
</script>

<style scoped></style>
